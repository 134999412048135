import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from './actions'
import { SettingDepartmentGroup } from 'src/types/management'
import { EntriesObject, WithOptional } from 'src/types/common'

export type ManagementSettingDepartmentGroupState = WithOptional<
  SettingDepartmentGroup,
  keyof SettingDepartmentGroup
> & {
  managerId: number | null
  departmentIds: number[] | null
}

export type DepartmentGroupChangeType<T extends keyof ManagementSettingDepartmentGroupState> = {
  key: T
  value: ManagementSettingDepartmentGroupState[T]
}

export const initialState: ManagementSettingDepartmentGroupState = {
  id: 0,
  name: '',
  managerId: null,
  departmentIds: null,
}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.managementSettingDepartmentGroupLoadData, (state, { payload: { departmentGroup } }) => {
      state.id = departmentGroup.id
      state.name = departmentGroup.name
      state.managerId = departmentGroup?.manager?.id
      state.departmentIds = departmentGroup?.departments?.map(department => department.id) || [];
    })
    .addCase(
      actions.managementSettingDepartmentGroupChange,
      <T extends keyof ManagementSettingDepartmentGroupState>(
        state: ManagementSettingDepartmentGroupState,
        { payload: { key, value } }: PayloadAction<DepartmentGroupChangeType<T>>
      ) => {
        state[key] = value
      }
    )
    .addCase(actions.managementSettingDepartmentGroupResetData, state => {
      ;(<EntriesObject>Object)
        .entries(initialState)
        .forEach(
          <T extends keyof ManagementSettingDepartmentGroupState>([key, value]: [
            T,
            ManagementSettingDepartmentGroupState[T]
          ]) => {
            state[key] = value
          }
        )
    })
})

export default reducer
