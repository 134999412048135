import { all, takeLatest, put, call } from 'redux-saga/effects'
import { userLogin, userLoginSuccess, userGetProfile, userGetProfileSuccess, userLogout } from './actions'
import { login, logout, profile } from 'src/apis/user/auth'

import { LoginForm } from 'src/types/common'
import handleError from 'src/utils/handleError'
import { USER_HOME_PAGE_LINK } from 'src/constants'

export const UserAuthKey = 'UserAuthKey'

type LoginAction = {
  type: string
  payload: LoginForm
}

function* userLoginSaga(action: LoginAction) {
  try {
    const { headers, data: { data } } = yield call(login, action.payload)
    yield put(userLoginSuccess(headers))

    // save into local storage
    localStorage.setItem(
      UserAuthKey,
      JSON.stringify({
        client: headers.client,
        'access-token': headers['access-token'],
        uid: headers.uid
      })
    )

    if (!data.passwordSetupAt) {
      return location.href = '/user/update_password'
    }

    location.href = USER_HOME_PAGE_LINK
  } catch (error) {
    handleError(error)
  }
}

function* userLogoutSaga() {
  try {
    yield call(logout)

    // remove local storage
    localStorage.removeItem(UserAuthKey)
    location.href = '/user/login'
  } catch (error) {
    handleError(error)
  }
}

function* userGetProfileSaga() {
  try {
    const { user } = yield call(profile)
    yield put(userGetProfileSuccess(user))
  } catch (error) {
    handleError(error)
  }
}

function* loginSaga() {
  yield all([
    takeLatest(userLogin.type, userLoginSaga),
    takeLatest(userLogout.type, userLogoutSaga),
    takeLatest(userGetProfile, userGetProfileSaga)
  ])
}

export default loginSaga
