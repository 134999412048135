import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { TrainingContent } from 'src/types/user'

export type UserTrainingContentsState = {
  trainingContents: TrainingContent[]
  searchConditions: {
    page: number
  }
  totalCount: number
}

export const initialState: UserTrainingContentsState = {
  trainingContents: [],
  searchConditions: {
    page: 1
  },
  totalCount: 0
}

const userTrainingContentsReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userTrainingContentsFetchSuccess, (state, action) => {
    state.trainingContents = action.payload.trainingContents
    state.totalCount = Math.ceil(action.payload.totalCount / 10)
  })
  builder.addCase(actions.userTrainingContentsUpdateSearchCondition, (state, action) => {
    state.searchConditions.page = action.payload.page
  })
})

export default userTrainingContentsReducer
