import axios from 'src/utils/axios'
import { TrainingStep } from 'src/types/common'

export type TraningStepUpdateData = {
  trainingStep: {
    thumbnailSignedId?: string
    name?: string,
  }
}

export const update = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  data
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  data: TraningStepUpdateData
}): Promise<{trainingStep: TrainingStep}> => {
  const res = await axios.put(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}`,
    data
  )

  return res.data
}

export const show = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
}): Promise<{trainingStep: TrainingStep}> => {
  const res = await axios.get(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}`
  )

  return res.data
}

export const create = async ({
  trainingContentId,
  trainingTypeId,
  name
}: {
  trainingContentId: number
  trainingTypeId: number
  name: string
}) => {
  const res = await axios.post(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps`,
    { trainingStep: { name } }
  )

  return res.data
}

export const destroy = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
}) => {
  const res = await axios.delete(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}`
  )

  return res.data
}
