import axios from 'src/utils/axios'
import { TrainingType } from 'src/types/common'

export type TrainingTypeUpdateData = {
  trainingType: {
    thumbnailSignedId?: string
    name?: string
    bigimageSignedId?: string
  }
}

export const create = async ({ trainingContentId, data }: { trainingContentId: number; data: TrainingTypeUpdateData }) => {
  const res = await axios.post(`/admin/training_contents/${trainingContentId}/training_types`, data)

  return res.data
}

export const show = async ({
  trainingContentId,
  trainingTypeId
}: {
  trainingContentId: number
  trainingTypeId: number
}): Promise<{ trainingType: TrainingType }> => {
  const res = await axios.get(`/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}`)

  return res.data
}

export const update = async ({
  trainingContentId,
  trainingTypeId,
  data
}: {
  trainingContentId: number
  trainingTypeId: number
  data: TrainingTypeUpdateData
}): Promise<{ trainingType: TrainingType }> => {
  const res = await axios.put(`/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}`, data)

  return res.data
}

export const destroy = async ({
  trainingContentId,
  trainingTypeId
}: {
  trainingContentId: number
  trainingTypeId: number
}) => {
  const res = await axios.delete(`/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}`)

  return res.data
}
