import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { TrainingContent } from 'src/types/user'
import { LectureInList } from 'src/types/common'

export type UserTrainingContentState = {
  trainingContent: TrainingContent | null
  lectures: LectureInList[]
  searchLectureConditions: {
    per: number
    page: number
    trainingTypeId?: number | null
  }
  totalLecturesCount: number
}

export const initialState: UserTrainingContentState = {
  trainingContent: null,
  lectures: [],
  searchLectureConditions: {
    page: 1,
    per: 10,
    trainingTypeId: null
  },
  totalLecturesCount: 0
}

const userTrainingContentReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userTrainingContentFetchSuccess, (state, action) => {
    state.trainingContent = action.payload.trainingContent
  })
  builder.addCase(actions.userTrainingContentLecturesFetchSuccess, (state, action) => {
    state.lectures = action.payload.lectures
    state.totalLecturesCount = Math.ceil(action.payload.meta.totalCount / state.searchLectureConditions.per)
  })
  builder.addCase(actions.userTrainingContentUpdateSearchCondition, (state, action) => {
    state.searchLectureConditions = {
      ...state.searchLectureConditions,
      ...action.payload
    }
  })
})

export default userTrainingContentReducer
