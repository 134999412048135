import { createAction } from '@reduxjs/toolkit'

import { AdminLectureState } from 'src/store/admin/lecture/reducer'

import {  Question } from 'src/types/common'
import {  QuestionPatch } from './reducer'

export const adminLectureLoadData = createAction<{
  lecture: AdminLectureState
}>('ADMIN_LECTURE_LOAD_DATA')

export const adminLectureResetData = createAction('ADMIN_LECTURE_RESET_DATA')

export const adminLectureChange = createAction<{
  key: 'name' | 'caution' | 'description'
  value: string
}>('ADMIN_LECTURE_CHANGE')

export const adminLectureAddMaterial = createAction<{
  fileSignedId: string
  fileName: string
  fileExtname: string
}>('ADMIN_LECTURE_ADD_MATERIAL')

export const adminLectureMaterialChange = createAction<{
  key: 'name'
  value: string
  lectureMaterialId: number
}>('ADMIN_LECTURE_MATERIAL_CHANGE')

export const adminLectureQuestionChange = createAction<QuestionPatch<keyof Question>>('ADMIN_LECTURE_QUESTION_CHANGE')

export const adminLectureAddQuestion = createAction('ADMIN_LECTURE_ADD_QUESTION')
