import { AppState } from './types'

import { initialState as adminTrainingContent } from 'src/store/admin/training_content/reducer'
import { initialState as adminLecture } from 'src/store/admin/lecture/reducer'
import { initialState as adminQuestion } from 'src/store/admin/question/reducer'
import { initialState as adminCompany } from 'src/store/admin/company/reducer'

import { initialState as userTrainingContents } from 'src/store/user/training_contents/reducer'
import { initialState as userTrainingContent } from 'src/store/user/training_content/reducer'
import { initialState as userTrainingContentCategories } from 'src/store/user/training_content_categories/reducer'
import { initialState as userExams } from 'src/store/user/user_exams/reducer'
import { initialState as userExamQuestions } from 'src/store/user/user_exam_questions/reducer'
import { initialState as userAssessmentLiteracy } from 'src/store/user/assessment_literacy/reducer'
import { initialState as userAssessmentDx } from 'src/store/user/assessment_dx/reducer'
import { initialState as userPassword } from 'src/store/user/update_password/reducer'

import { initialState as managementSettingDepartmentGroup } from 'src/store/management/setting/department_group/reducer'
import { initialState as managementSettingDepartment } from 'src/store/management/setting/department/reducer'
import { initialState as managementTmsUsers } from 'src/store/management/tms/users/reducer'

const initialState: AppState = {
  adminLogin: {
    client: '',
    'access-token': '',
    uid: ''
  },
  userLogin: {
    client: '',
    'access-token': '',
    uid: ''
  },
  managementLogin: {
    client: '',
    'access-token': '',
    uid: ''
  },
  userTrainingContentCategories,
  userTrainingContents,
  userTrainingContent,
  adminTrainingContent,
  adminLecture,
  adminQuestion,
  adminCompany,
  userExams,
  userExamQuestions,
  userAssessmentLiteracy,
  userAssessmentDx,
  userPassword,

  managementSettingDepartmentGroup,
  managementSettingDepartment,
  managementTmsUsers,
}

export default initialState
