import { all, takeLatest, put, call } from 'redux-saga/effects'
import { userTrainingContentsFetch, userTrainingContentsFetchSuccess } from './actions'
import * as UserTrainingContentApi from 'src/apis/user/training_content'
import { TrainingContentsSearch } from 'src/apis/user/training_content'
import { IndexResponseType } from 'src/apis/user/training_content'

import handleError from 'src/utils/handleError'

export const UserAuthKey = 'UserAuthKey'

type LoginAction = {
  type: string
  payload: TrainingContentsSearch
}

function* userTrainingContentsFetchSaga(action: LoginAction) {
  try {
    const trainingContents: IndexResponseType = yield call(UserTrainingContentApi.index, action.payload)
    yield put(
      userTrainingContentsFetchSuccess({
        trainingContents: trainingContents.trainingContents,
        totalCount: trainingContents.totalCount || 0
      })
    )
  } catch (error) {
    handleError(error)
  }
}

function* userTrainingContentsSaga() {
  yield all([takeLatest(userTrainingContentsFetch.type, userTrainingContentsFetchSaga)])
}

export default userTrainingContentsSaga
