import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from './actions'
import initialState from 'src/store/state'
import { AuthInfo } from 'src/types/common'

export type AdminLoginState = {
  client: string
  'access-token': string
  uid: string
}

const adminLoginReducer = createReducer(initialState.adminLogin, builder => {
  builder.addCase(actions.adminLoginSuccess, (state: AdminLoginState, action: PayloadAction<AuthInfo>) => {
    state.client = action.payload.client
    state['access-token'] = action.payload['access-token']
    state.uid = action.payload.uid
  })
})

export default adminLoginReducer
