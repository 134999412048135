import axios from 'src/utils/axios'
import { TrainingContent, TrainingContentProgress } from 'src/types/user'

export type TrainingContentsSearch = {
  slugs: {
    trainingContentCategoryId: number | string
  }
  params: TrainingContentsSearchParam
}

export type TrainingContentsSearchParam = {
  page: number
}

export type IndexResponseType = {
  trainingContents: TrainingContent[]
  totalCount?: number
}

export type TrainingContentShow = {
  trainingContentId: number | string
  trainingContentCategoryId: number | string
}

export const index = async ({ slugs, params }: TrainingContentsSearch): Promise<IndexResponseType> => {
  const { data } = await axios.get(
    `/account/training_content_categories/${slugs.trainingContentCategoryId}/training_contents`,
    { params }
  )
  if (data.trainingContent) {
    return {
      trainingContents: [data.trainingContent]
    }
  }

  return {
    trainingContents: data.trainingContents,
    totalCount: data.meta.totalCount
  }
}

export const show = async ({ trainingContentId, trainingContentCategoryId }: TrainingContentShow): Promise<TrainingContent> => {
  const { data } = await axios.get(
    `/account/training_content_categories/${trainingContentCategoryId}/training_contents/${trainingContentId}`
  )

  return data.trainingContent
}

export const progress = async (trainingContentId: number): Promise<TrainingContentProgress> => {
  const { data } = await axios.get(
    `/account/training_contents/${trainingContentId}/progress`
  )

  return data
}
