import { Role } from 'src/types/common'

export const TRAINING_CONTENT_STATUS_LIST = [
  {
    name: '公開',
    value: 'published'
  },
  {
    name: 'ドラフト',
    value: 'draft'
  }
]

export const ROLES = {
  employee: 1,
  back_office: 2,
  manager: 3,
  general_manager: 4
}

export const ROLES_TEXT = {
  employee: 'employee',
  backOffice: 'back_office',
  manager: 'manager',
  generalManager: 'general_manager'
}

export const STAGE_TYPES = {
  NONE: 'none',
  DX: 'dx',
  LITERACY: 'literacy'
}

export const USER_HOME_PAGE_LINK = '/user/home'
export const STARTUP_USER_PAGE_LINK = '/user/training_content_categories'
export const STARTUP_MANAGEMENT_PAGE_LINK: {
  [K in Role]?: string
} = {
  back_office: '/management/tms/departments',
  manager: '/management/tms/departments',
  general_manager: '/management/tms/departments'
}

export const ASSESSMENT_LITERACY_PATHNAMES = [
  `/user/assessments/start`,
  `/user/assessments/literacy`,
  `/user/assessments/literacy/result`
]

export const COMMON_DEFAULT_PER = 10

export const POINT_TO_PASS_LECTURE = 80

export const DEFAULT_PER_ALL_OPTIONS = 1000

export const UPDATE_PASSWORD_PATH = '/user/update_password'

export const MANAGEMENT_UPDATE_PASSWORD_PATH = '/management/update_password'

export const DEFAULT_RANK = '-'
export const RANK_A_ID = 'A'
export const RANK_B_ID = 'B'
export const RANK_C_ID = 'C'

export const SKILL_RANKS = {
  [DEFAULT_RANK]: {
    backgroundColor: 'rank.none',
    label: '-'
  },
  [RANK_A_ID]: {
    backgroundColor: 'rank.a',
    label: 'A'
  },
  [RANK_B_ID]: {
    backgroundColor: 'rank.b',
    label: 'B'
  },
  [RANK_C_ID]: {
    backgroundColor: 'rank.c',
    label: 'C'
  }
}

export const NOT_FOUND_INDEX = -1
export const DASH_OPTION_ID = -1

export const REPORT_CONTENTS = [
  {
    id: 1,
    name: '正解の選択肢がおかしい'
  },
  {
    id: 2,
    name: '問題文に誤りがある'
  },
  {
    id: 3,
    name: '解説文に誤りがある'
  },
  {
    id: 4,
    name: 'その他'
  }
]

export const STUDY_STATUS_TYPE = {
  dxLiteracy: 'DXリテラシー',
  dxSkill: 'DX推進スキル',
  itPassport: 'ITパスポート'
}

export const FIRST_PAGE = 1

export const DUMMY_COLOR_LINE_CHART = [
  {
    linearColorFirst: '#00BCD4',
    linearColorEnd: '#00BCD400',
    dominantColor: '#57C5F0'
  },
  {
    linearColorFirst: '#6DB3814D',
    linearColorEnd: '#6DB38100',
    dominantColor: '#6DB381'
  },
  {
    linearColorFirst: '#ECB22D4D',
    linearColorEnd: '#ECB22D00',
    dominantColor: '#ECB22D'
  },
  {
    linearColorFirst: '#E01E5A4D',
    linearColorEnd: '#E01E5A00',
    dominantColor: '#E01E5A'
  }
]

export const LITERACY_CHART_FILTER = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly'
}

export const MIN_RADAR_CHART_DATA = 5
export const MAX_RADAR_CHART_DATA = 45
export const DX_SKILL_GROUP_NAME = 'DX推進スキル'

export const AUTHORITY_TITLE = 'プラン制限'

export const AUTHORITY_LIMIT = {
  department: 1,
  departmentGroup: 1,
  user: 10
}

export const AUTHORITY_MESSAGES = {
  limitDepartmentGroupEqual: '現在のプランでは、事業部をひとつまでしか登録できません',
  limitDepartmentGroupGreater: '現在のプランでは、これ以上事業部を登録できません',
  limitDepartmentEqual: '現在のプランでは、部署をひとつまでしか登録できません',
  limitDepartmentGreater: '現在のプランでは、これ以上部署を登録できません',
  limitUserEqual: '現在のプランでは、従業員を10人までしか登録できません',
  limitUserGreater: '現在のプランでは、これ以上従業員を登録できません',
  visualizeSkill: 'このサービスは有料プランでのみご利用いただけます',
  withoutGeneralManager: '権限がありません。'
}

export const PRIVILEGE_TYPES = {
  registerDepartmentNoLimit: 'register_department_no_limit',
  registerUserNoLimit: 'register_user_no_limit',
  visualizeSkill: 'visualize_skill',
  viewLiteracyVideo: 'view_literacy_video',
  viewDxSkillVideo: 'view_dx_skill_video',
  viewLiteracyVideoLimited: 'view_literacy_video_limited',
  viewDxSkillVideoLimited: 'view_dx_skill_video_limited'
}

export const BILLING_PLAN_ICONS = {
  arrowUp: 'red-up-arrow'
}

export const TRIAL_DATE_CLOSE_TO_FINISHED = 3

export const BILLING_PLAN_PERIODS = {
  monthly: 'monthly',
  yearly: 'yearly'
}

export const BILLING_PLAN_IDS = {
  trial: 1,
  free: 2,
  paid: 3
}

export const PROCESSING_NEW = '新規'
export const PROCESSING_RE_NEW = '更新'

export const ROLE_LIMIT_TITLE = 'ロール制限'

export const SECOND_PER_QUESTION = 80

export const FIRST_SCORE_RETRIEVAL_RATE_THRESHOLD = 0.9
