import axios from 'src/utils/axios'

import { LoginForm, AuthInfo } from 'src/types/common'

export type ForgotPasswordType = {
  email: string
  redirectUrl: string
}

export type EditPasswordType = {
  password: string
  passwordConfirmation: string
}

export type GetResetPasswordTokenType = {
  resetPasswordToken: string
}

export const login = async (data: LoginForm) => {
  const res = await axios.post('/admin/login', data)

  // headersを使うため、res.dataを使わない

  return res
}

export const logout = async () => {
  const res = await axios.delete('/admin/logout')

  return res
}

export const forgotPassword = async (data: ForgotPasswordType) => {
  const res = await axios.post('/admin/password/reset', data)

  return res.data
}

export const editPassword = async (data: EditPasswordType, headers: AuthInfo) => {
  const res = await axios.put('/admin/password/reset', data, { headers })

  return res
}

type GetResetPasswordTokenResponseType = {
  redirectHeaders: AuthInfo & { accessToken: string }
}

export const getResetPasswordToken = async (
  params: GetResetPasswordTokenType
): Promise<GetResetPasswordTokenResponseType> => {
  const res = await axios.get('/admin/password/reset', { params })

  return res.data
}
