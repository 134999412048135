import axios from 'src/utils/axios'
import { TrainingContentCategory } from 'src/types/user'

export type TrainingContentCategorySearch = {
  page: number
}

export type IndexResponseType = {
  trainingContentCategories: TrainingContentCategory[]
  totalCount?: number
}

export const index = async (params: TrainingContentCategorySearch): Promise<IndexResponseType> => {
  const { data } = await axios.get('/account/training_content_categories', { params })

  return data;
}

export const show = async (trainingContentCategoryId: number): Promise<TrainingContentCategory> => {
  const { data } = await axios.get(`/account/training_content_categories/${trainingContentCategoryId}`)

  return data.trainingContentCategory;
}
