import { createAction } from '@reduxjs/toolkit'
import { UserUpdatePasswordState, UserUpdatePasswordChangeType } from 'src/store/user/update_password/reducer'
import { FormUpdatePasswordType } from 'src/apis/user/user'

export const userUpdatePasswordChange =
  createAction<UserUpdatePasswordChangeType<keyof UserUpdatePasswordState>>('USER_UPDATE_PASSWORD_CHANGE')
export const userUpdatePasswordResetData = createAction('USER_UPDATE_PASSWORD_RESET_DATA')

export const userUpdatePassword = createAction<FormUpdatePasswordType>('USER_UPDATE_PASSWORD')
export const userUpdatePasswordSuccess = createAction('USER_UPDATE_PASSWORD_SUCCESS')
