import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import * as actions from './actions'

import { Question, EntriesObject, QuestionOption } from 'src/types/common'

export type AdminQuestionState = Question & {
  thumbnailSignedId?: string
  nextId?: number | null
  prevId?: number | null
}
export type AdminQuestionKeyType = Pick<
  AdminQuestionState,
  'name' | 'problemText' | 'commentaryText' | 'thumbnailSignedId' | 'fileUrl' | 'questionTypeId'
>
export type AdminQuestionType<T extends keyof AdminQuestionKeyType> = {
  key: T
  value: AdminQuestionState[T]
}

export type AdminQuestionOptionType<T extends keyof QuestionOption> = {
  key: T
  value: QuestionOption[T]
  index: number
}

export const initialState: AdminQuestionState = {
  id: -1,
  name: '',
  problemText: '',
  questionType: {
    id: -1,
    name: ''
  },
  questionTypeId: 0,
  priority: 0,
  commentaryText: '',
  questionOptions: ['ア', 'イ', 'ウ', 'エ'].map((name, index) => ({
    name,
    sentence: '',
    isCorrect: index === 0 ? true : false,
    priority: index + 1
  })),
}

const adminQuestionReducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.adminQuestionLoadData, (state, { payload: { question } }) => {
      state.id = question.id
      state.questionTypeId = question.questionTypeId
      state.questionType = question.questionType
      state.name = question.name
      state.problemText = question.problemText
      state.commentaryText = question.commentaryText
      state.questionOptions = question.questionOptions
      state.fileUrl = question.fileUrl
      state.prevId = question.prevId
      state.nextId = question.nextId
      if (question.questionOptions.length === 0) {
        state.questionOptions = ['ア', 'イ', 'ウ', 'エ'].map((name, index) => ({
          name,
          sentence: '',
          isCorrect: false,
          priority: index + 1
        }))
      }
    })
    .addCase(
      actions.adminQuestionChange,
      <T extends keyof AdminQuestionKeyType>(
        state: AdminQuestionState,
        { payload: { key, value } }: PayloadAction<AdminQuestionType<T>>
      ) => {
        state[key] = value
      }
    )
    .addCase(
      actions.adminQuestionOptionChange,
      <T extends keyof QuestionOption>(
        state: AdminQuestionState,
        { payload: { key, value, index } }: PayloadAction<AdminQuestionOptionType<T>>
      ) => {
        if (key === 'isCorrect') {
          state.questionOptions.forEach(questionOption => {
            questionOption.isCorrect = false
          })
        }
        state.questionOptions[index][key] = value
      }
    )
    .addCase(actions.adminQuestionResetData, state => {
      ;(<EntriesObject>Object)
        .entries(initialState)
        .forEach(<T extends keyof AdminQuestionState>([key, value]: [T, AdminQuestionState[T]]) => {
          state[key] = value
        })
    })
})

export default adminQuestionReducer
