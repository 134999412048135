import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'
import { Question } from 'src/types/common'

export type DxSkillType = {
  id: number;
  name: string;
  learned: null | boolean;
}
export type DxSkillsByImportanceType = {
  mostImportantSkills: DxSkillType[];
  importantSkills: DxSkillType[];
  normalSkills: DxSkillType[];
  commonSkills: DxSkillType[];
}

export type DxRoleResultType = {
  id: number;
  name: string;
  userScore: number;
  dxSkillsByImportance: DxSkillsByImportanceType;
  dxRoleCategory: {
      id: number;
      name: string;
  };
}

export type AssessmentDxResultType = {
  dxRoles: DxRoleResultType[];
}



export type UserAssessmentDxState = {
  questions: Question[],
  result: AssessmentDxResultType | null,
}

export const initialState: UserAssessmentDxState = {
  questions: [],
  result: null,
}

const userAssessmentDx = createReducer(initialState, builder => {
  builder.addCase(actions.userAssessmentDxSetQuestions, (state, action) => {
    state.questions = action.payload;
  })
  builder.addCase(actions.userAssessmentDxSetResult, (state, action) => {
    state.result = action.payload;
  })
})

export default userAssessmentDx
