import { all, takeLatest, put } from 'redux-saga/effects'
import { call } from "typed-redux-saga";

import {
  managementLogin,
  managementLoginSuccess,
  managementLogout,
  managementUserGetProfile,
  managementUserGetProfileSuccess
} from './actions'

import { login, logout } from 'src/apis/management/user'
import { profile } from 'src/apis/user/auth'

import { LoginForm, AuthInfo } from 'src/types/common'
import handleError from 'src/utils/handleError'
import { STARTUP_MANAGEMENT_PAGE_LINK } from 'src/constants'

export const ManagementAuthKey = 'ManagementAuthKey'

type LoginAction = {
  type: string
  payload: LoginForm
}

function* userLoginSaga(action: LoginAction) {
  try {
    const { headers, data: { data } } = yield* call(login, action.payload)
    yield put(managementLoginSuccess(headers as AuthInfo))

    // save into local storage
    localStorage.setItem(
      ManagementAuthKey,
      JSON.stringify({
        client: headers.client,
        'access-token': headers['access-token'],
        uid: headers.uid
      })
    )

    if (data.passwordSetupAt) {
      location.href = STARTUP_MANAGEMENT_PAGE_LINK[data.roleKey] as string;
    } else {
      location.href = '/management/update_password'
    }
  } catch (error) {
    handleError(error)
  }
}

function* userLogoutSaga() {
  try {
    yield call(logout)

    // remove local storage
    localStorage.removeItem(ManagementAuthKey)
    location.href = '/management/login'
  } catch (error) {
    handleError(error)
  }
}

function* managementUserGetProfileSaga() {
  try {
    const { user } = yield call(profile)
    yield put(managementUserGetProfileSuccess(user))
  } catch (error) {
    handleError(error)
  }
}

function* loginSaga() {
  yield all([
    takeLatest(managementLogin.type, userLoginSaga),
    takeLatest(managementLogout.type, userLogoutSaga),
    takeLatest(managementUserGetProfile.type, managementUserGetProfileSaga)
  ])
}

export default loginSaga
