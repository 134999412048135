import { createAction } from '@reduxjs/toolkit'

import { AdminQuestionState } from 'src/store/admin/question/reducer'
import { QuestionOption } from 'src/types/common'
import {  AdminQuestionType, AdminQuestionOptionType, AdminQuestionKeyType  } from './reducer'


export const adminQuestionLoadData = createAction<{
  question: AdminQuestionState
}>('ADMIN_QUESTION_LOAD_DATA')

export const adminQuestionResetData = createAction('ADMIN_QUESTION_RESET_DATA')

export const adminQuestionChange = createAction<AdminQuestionType<keyof AdminQuestionKeyType >>('ADMIN_QUESTION_CHANGE')

export const adminQuestionOptionChange = createAction<AdminQuestionOptionType<keyof QuestionOption>>('ADMIN_QUESTION_OPTION_CHANGE')
