// ** Types
import { NextRouter } from 'next/router'
import {
  DX_ASSESSMENT,
  LITERACY_ASSESSMENT,
  NORMAL_LECTURE,
  OTHERS
} from 'src/components/ui_parts/chikamichi/common/problem_report_modal'

/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param item
 * @param activeItem
 */
export const handleURLQueries = (router: NextRouter, path: string | undefined): boolean => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query)

    return router.asPath.includes(path) && router.asPath.includes(router.query[arr[0]] as string) && path !== '/'
  }

  return false
}

export const getRouteType = (path: string) => {
  if (path === '/user/dx_skill_assetments/dx_role_categories/training') {
    return DX_ASSESSMENT
  }
  if (path === '/user/assessments/literacy') {
    return LITERACY_ASSESSMENT
  }
  if (
    path ===
    '/user/training_content_categories/[trainingContentCategoryId]/training_contents/[trainingContentId]/lectures/[lectureId]/training'
  ) {
    return NORMAL_LECTURE
  }

  return OTHERS
}
