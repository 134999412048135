import PropTypes from 'prop-types'
import { toast, TypeOptions } from 'react-toastify'

type Toast = {
  type?: Exclude<TypeOptions, 'default'>
  message: string
}

toast.success

const ToastMessage = ({ type, message }: Toast) => {
  switch (type) {
    case 'error':
      return toast.error(message)
    case 'success':
      return toast.success(message)
    case 'warning':
      return toast.warning(message)
    case 'info':
    default:
      return toast.info(message)
  }
}

ToastMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

ToastMessage.dismiss = toast.dismiss

export default ToastMessage
