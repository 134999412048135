import { all, takeLatest, put, call } from 'redux-saga/effects'
import {
  userTrainingContentFetch,
  userTrainingContentFetchSuccess,
  userTrainingContentLecturesFetch,
  userTrainingContentLecturesFetchSuccess
} from './actions'
import * as UserTrainingContentApi from 'src/apis/user/training_content'
import * as UserLectureApi from 'src/apis/user/lecture'
import { TrainingContentLecturesResponseType } from 'src/apis/user/lecture'

import { TrainingContent } from 'src/types/user'
import { TrainingContentLecturesSearch } from 'src/apis/user/lecture'
import { TrainingContentShow  } from 'src/apis/user/training_content'

import handleError from 'src/utils/handleError'

export const UserAuthKey = 'UserAuthKey'

type FetchTrainingContentAction = {
  type: string
  payload: TrainingContentShow
}

type FetchTrainingContentLecturesAction = {
  type: string
  payload: {
    trainingContentId: number
    params: TrainingContentLecturesSearch
  }
}

function* userTrainingContentFetchSaga(action: FetchTrainingContentAction) {
  try {
    const trainingContent: TrainingContent = yield call(UserTrainingContentApi.show, action.payload)
    yield put(
      userTrainingContentFetchSuccess({
        trainingContent
      })
    )
  } catch (error) {
    handleError(error)
  }
}

function* userTrainingContentLecturesFetchSaga(action: FetchTrainingContentLecturesAction) {
  try {
    const response: TrainingContentLecturesResponseType = yield call(UserLectureApi.index, action.payload)
    yield put(userTrainingContentLecturesFetchSuccess(response))
  } catch (error) {
    handleError(error)
  }
}

function* userTrainingContentSaga() {
  yield all([
    takeLatest(userTrainingContentFetch.type, userTrainingContentFetchSaga),
    takeLatest(userTrainingContentLecturesFetch.type, userTrainingContentLecturesFetchSaga)
  ])
}

export default userTrainingContentSaga
