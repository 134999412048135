import { all, takeLatest, put, call } from 'redux-saga/effects'
import { userUpdatePassword, userUpdatePasswordSuccess } from './actions'
import * as UserApi from 'src/apis/user/user'
import { FormUpdatePasswordType } from 'src/apis/user/user'

import handleError from 'src/utils/handleError'

export const UserAuthKey = 'UserAuthKey'

type UserUpdatePasswordAction = {
  type: string
  payload: FormUpdatePasswordType
}

function* userUpdatePasswordWorker(action: UserUpdatePasswordAction) {
  try {
    yield call(UserApi.updatePassword, action.payload)
    yield put(userUpdatePasswordSuccess())
  } catch (error) {
    handleError(error)
  }
}

function* userUpdatePasswordSaga() {
  yield all([takeLatest(userUpdatePassword.type, userUpdatePasswordWorker)])
}

export default userUpdatePasswordSaga
