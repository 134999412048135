import axios from 'src/utils/axios'

import { AdminTrainingContentState } from 'src/store/admin/training_content/reducer'
import { PaginationMeta, TrainingContent } from 'src/types/common'

export type TrainingContentsSearch = {
  page: number
  per?: number
}

export type TraningContentsResponse = {
  trainingContents: TrainingContent[]
  meta: PaginationMeta
}

export const index = async (params: TrainingContentsSearch): Promise<TraningContentsResponse> => {
  const res = await axios.get('/admin/training_contents', { params })

  return res.data
}

export const create = async (trainingContent: AdminTrainingContentState) => {
  const res = await axios.post('/admin/training_contents', { trainingContent })

  return res.data
}

export const show = async (id: number) => {
  const res = await axios.get(`/admin/training_contents/${id}`)

  return res.data
}

export const destroy = async (id: number) => {
  const res = await axios.delete(`/admin/training_contents/${id}`)

  return res
}

export const update = async (id: number, trainingContent: AdminTrainingContentState) => {
  const res = await axios.put(`/admin/training_contents/${id}`, { trainingContent })

  return res
}
