import React, { forwardRef } from 'react'

import { Dialog, DialogContent, Slide, AppBar, Toolbar, DialogActions, Button } from '@mui/material'

import { TransitionProps } from '@mui/material/transitions'
import { useRouter } from 'next/router'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'src/utils/themeManagement'
import { useGlobalModalContext } from 'src/views/modal/GlobalModal'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />
})

const ModalFormUpdatePassword = () => {
  const router = useRouter()
  const { hideManagementUpdatePasswordModal } = useGlobalModalContext()
  const goToUpdatePasswordPage = () => {
    hideManagementUpdatePasswordModal();
    router.push('/management/update_password')
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={true} TransitionComponent={Transition} maxWidth='sm' fullWidth={true}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>パスワード更新</Toolbar>
        </AppBar>
        <DialogContent sx={{maxWidth: "100%"}}>パスワードを変更してください。</DialogContent>
        <DialogActions>
          <Button onClick={goToUpdatePasswordPage} variant='contained' color='primary'>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default ModalFormUpdatePassword
