import { createAction } from '@reduxjs/toolkit'

import { ManagementTmsUserSearch, ManagementTmsUsersResponseType } from 'src/apis/management/tms/user'

export const managementTmsUsersUpdateSearchCondition = createAction<ManagementTmsUserSearch>(
  'MANAGEMENT_TMS_USERS_UPDATE_SEARCH_CONDITION'
)

export const managementTmsUsersFetch = createAction<ManagementTmsUserSearch>('MANAGEMENT_TMS_USERS_FETCH')
export const managementTmsUsersFetchSuccess = createAction<ManagementTmsUsersResponseType>('MANAGEMENT_TMS_USERS_FETCH_SUCCESS')
