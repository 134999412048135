import axios from 'src/utils/axios'

export const show = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  lectureId
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  lectureId: number
}) => {
  const res = await axios.get(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}/lectures/${lectureId}`
  )

  return res.data
}

export const create = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  data
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  data: any
}) => {
  const res = await axios.post(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}/lectures`,
    { lecture: data }
  )

  return res.data
}

export const update = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  lectureId,
  data
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  lectureId: number
  data: any
}) => {
  const res = await axios.put(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}/lectures/${lectureId}`,
    { lecture: data }
  )

  return res.data
}

export const destroy = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  lectureId
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  lectureId: number
}) => {
  const res = await axios.delete(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}/lectures/${lectureId}`
  )

  return res.data
}

export const downloadAllLectureMaterials = async ({
  trainingContentId,
  trainingTypeId,
  trainingStepId,
  lectureId
}: {
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  lectureId: number
}) => {
  const res = await axios.get(
    `/admin/training_contents/${trainingContentId}/training_types/${trainingTypeId}/training_steps/${trainingStepId}/lectures/${lectureId}/lecture_materials/downloads`,
    {
      responseType: 'blob'
    }
  )

  return res.data
}
