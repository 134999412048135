import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  adminTrainingContentFetchData,
  adminTrainingContentLoadData,
  adminTrainingContentDeleteLecture,
  adminTrainingContentDeleteTrainingStep,
  adminTrainingContentDeleteTrainingType
} from './actions'
import handleError from 'src/utils/handleError'
import * as TraningContentApi from 'src/apis/admin/training_content'
import * as LectureApi from 'src/apis/admin/lecture'
import * as TrainingStepApi from 'src/apis/admin/training_step'
import * as TrainingTypeApi from 'src/apis/admin/training_type'

import toast from 'src/views/toast'

type FetchDataActionType = {
  type: string
  payload: {
    trainingContentId: number
  }
}

type DeleteLectureActionType = {
  type: string
  payload: {
    trainingContentId: number
    trainingTypeId: number
    trainingStepId: number
    lectureId: number
  }
}

type DeleteTrainingStepActionType = {
  type: string
  payload: {
    trainingContentId: number
    trainingTypeId: number
    trainingStepId: number
  }
}

type DeleteTrainingTypeActionType = {
  type: string
  payload: {
    trainingContentId: number
    trainingTypeId: number
  }
}

function* adminTrainingContentFetchDataSaga({ payload: { trainingContentId } }: FetchDataActionType) {
  try {
    const { trainingContent } = yield call(TraningContentApi.show, trainingContentId)
    yield put(adminTrainingContentLoadData({ trainingContent }))
  } catch (error) {
    handleError(error)
  }
}

function* adminTrainingContentDeleteLectureSaga({ payload }: DeleteLectureActionType) {
  try {
    yield call(LectureApi.destroy, {
      trainingContentId: payload.trainingContentId,
      trainingTypeId: payload.trainingTypeId,
      trainingStepId: payload.trainingStepId,
      lectureId: payload.lectureId,
    })
    toast({ type: 'success', message: '削除しました。' })
    yield put(adminTrainingContentFetchData({ trainingContentId: payload.trainingContentId }))
  } catch (error) {
    handleError(error)
  }
}

function* adminTrainingContentDeleteStepSaga({ payload }: DeleteTrainingStepActionType) {
  try {
    yield call(TrainingStepApi.destroy, {
      trainingContentId: payload.trainingContentId,
      trainingTypeId: payload.trainingTypeId,
      trainingStepId: payload.trainingStepId,
    })
    toast({ type: 'success', message: '削除しました。' })
    yield put(adminTrainingContentFetchData({ trainingContentId: payload.trainingContentId }))
  } catch (error) {
    handleError(error)
  }
}

function* adminTrainingContentDeleteTypeSaga({ payload }: DeleteTrainingTypeActionType) {
  try {
    yield call(TrainingTypeApi.destroy, {
      trainingContentId: payload.trainingContentId,
      trainingTypeId: payload.trainingTypeId,
    })
    toast({ type: 'success', message: '削除しました。' })
    yield put(adminTrainingContentFetchData({ trainingContentId: payload.trainingContentId }))
  } catch (error) {
    handleError(error)
  }
}


function* adminTrainingContentSaga() {
  yield all([
    takeLatest(adminTrainingContentFetchData.type, adminTrainingContentFetchDataSaga),
    takeLatest(adminTrainingContentDeleteLecture.type, adminTrainingContentDeleteLectureSaga),
    takeLatest(adminTrainingContentDeleteTrainingStep.type, adminTrainingContentDeleteStepSaga),
    takeLatest(adminTrainingContentDeleteTrainingType.type, adminTrainingContentDeleteTypeSaga)
  ])
}

export default adminTrainingContentSaga
