/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, createContext, useContext } from 'react'
import { DeleteModal } from './DeleteModal'
import ModalFormUpdatePassword from 'src/components/pages/user/update_password/modal_form_update_password'
import ManagementModalFormUpdatePassword from 'src/components/pages/management/update_password/modal_form_update_password'
import { ConfirmModal } from './ConfirmModal'

const DELETE_MODAL = 'DELETE_MODAL'
const CONFIRM_MODAL = 'CONFIRM_MODAL'

type ModalProps = {
  title: string
  message: string
  cancel?: string
  button?: string
  confirmFunc?: () => void
  cancelFunc?: () => void
}

type GlobalModalContext = {
  showDeleteModal: (modalProps: ModalProps) => void
  hideDeleteModal: () => void
  showConfirmModal: (modalProps: ModalProps) => void
  hideConfirmModal: () => void
  showUpdatePasswordModal: () => void
  hideUpdatePasswordModal: () => void
  showManagementUpdatePasswordModal: () => void
  hideManagementUpdatePasswordModal: () => void
  store: any
}

const initalState: GlobalModalContext = {
  showDeleteModal: () => {},
  hideDeleteModal: () => {},
  showConfirmModal: () => {},
  hideConfirmModal: () => {},
  showUpdatePasswordModal: () => {},
  hideUpdatePasswordModal: () => {},
  showManagementUpdatePasswordModal: () => {},
  hideManagementUpdatePasswordModal: () => {},
  store: {}
}

const GlobalModalContext = createContext(initalState)
export const useGlobalModalContext = () => useContext(GlobalModalContext)

export const GlobalModal: any = ({ children }: any) => {
  const [store, setStore] = useState({})
  const [managementUpdatePasswordModalIsVisible, setManagementUpdatePasswordModalIsVisible] = useState(false)
  const [updatePasswordModalIsVisible, setUpdatePasswordModalIsVisible] = useState(false)
  const { modalType, modalProps }: any = store || {}

  const showDeleteModal = (modalProps: any = {}) => {
    setStore({
      ...store,
      modalType: DELETE_MODAL,
      modalProps
    })
  }

  const hideDeleteModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    })
  }

  const showConfirmModal = (modalProps: any = {}) => {
    setStore({
      ...store,
      modalType: CONFIRM_MODAL,
      modalProps
    })
  }

  const hideConfirmModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    })
  }

  const showUpdatePasswordModal = () => {
    setUpdatePasswordModalIsVisible(true);
  }

  const hideUpdatePasswordModal = () => {
    setUpdatePasswordModalIsVisible(false);
  }

  const showManagementUpdatePasswordModal = () => {
    setManagementUpdatePasswordModalIsVisible(true)
  }

  const hideManagementUpdatePasswordModal = () => {
    setManagementUpdatePasswordModalIsVisible(false)
  }

  const renderComponent = () => {
    if (modalType === DELETE_MODAL) {
      return <DeleteModal {...modalProps} />
    } else if (modalType === CONFIRM_MODAL) {
      return <ConfirmModal {...modalProps} />
    }
  }

  return (
    <GlobalModalContext.Provider
      value={{
        store,
        showDeleteModal,
        hideDeleteModal,
        showConfirmModal,
        hideConfirmModal,
        showUpdatePasswordModal,
        hideUpdatePasswordModal,
        showManagementUpdatePasswordModal,
        hideManagementUpdatePasswordModal
      }}
    >
      {renderComponent()}
      {managementUpdatePasswordModalIsVisible && <ManagementModalFormUpdatePassword />}
      {updatePasswordModalIsVisible && <ModalFormUpdatePassword />}
      {children}
    </GlobalModalContext.Provider>
  )
}
