import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { TmsUser } from 'src/types/management'
import { ManagementTmsUserSearch } from 'src/apis/management/tms/user'

export type ManagementTmsUsersState = {
  users: TmsUser[]
  searchConditions: ManagementTmsUserSearch
  totalCount: number,
}

const DEFAULT_PER = 10

export const initialState: ManagementTmsUsersState = {
  users: [],
  searchConditions: {
    per: DEFAULT_PER,
    page: 1,
    departmentId: null,
    departmentGroupId: null,
  },
  totalCount: 0
}

const managementTmsUsersReducer = createReducer(initialState, builder => {
  builder.addCase(actions.managementTmsUsersFetchSuccess, (state, action) => {
    state.users = action.payload.users

    // state.totalCount = Math.ceil(action.payload.meta.totalCount / (state.searchConditions.per || DEFAULT_PER))
  })
  builder.addCase(actions.managementTmsUsersUpdateSearchCondition, (state, action) => {
    state.searchConditions = {
      ...state.searchConditions,
      ...action.payload
    }
  })
})

export default managementTmsUsersReducer
