import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { TrainingContentCategory } from 'src/types/user'

export type UserTrainingContentCategoriesState = {
  trainingContentCategories: TrainingContentCategory[]
  searchConditions: {
    page: number
  }
  totalCount: number
}

export const initialState: UserTrainingContentCategoriesState = {
  trainingContentCategories: [],
  searchConditions: {
    page: 1
  },
  totalCount: 0
}

const userTrainingContentCategoriesReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userTrainingContentCategoriesFetchSuccess, (state, action) => {
    state.trainingContentCategories = action.payload.trainingContentCategories
    state.totalCount = Math.ceil(action.payload.totalCount / 10)
  })
  builder.addCase(actions.userTrainingContentCategoriesUpdateSearchCondition, (state, action) => {
    state.searchConditions.page = action.payload.page
  })
})

export default userTrainingContentCategoriesReducer
