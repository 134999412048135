import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from './actions'
import { SettingDepartment } from 'src/types/management'
import { EntriesObject, WithOptional } from 'src/types/common'

export type ManagementSettingDepartmentState = WithOptional<
  SettingDepartment,
  keyof SettingDepartment
> & {
  managerId: number | null,
  departmentGroupIds?: number[],
  userIds: number[],
}

export type DepartmentChangeType<T extends keyof ManagementSettingDepartmentState> = {
  key: T
  value: ManagementSettingDepartmentState[T]
}

export const initialState: ManagementSettingDepartmentState = {
  id: 0,
  name: '',
  managerId: null,
  departmentGroupIds: [],
  userIds: [],
}

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.managementSettingDepartmentLoadData, (state, { payload: { department } }) => {
      state.id = department.id
      state.name = department.name
      state.managerId = department?.manager?.id
      state.departmentGroupIds = department.departmentGroups.map(departmentGroup => departmentGroup.id);
      state.userIds = department?.users.map(user => user.id) || [];
    })
    .addCase(
      actions.managementSettingDepartmentChange,
      <T extends keyof ManagementSettingDepartmentState>(
        state: ManagementSettingDepartmentState,
        { payload: { key, value } }: PayloadAction<DepartmentChangeType<T>>
      ) => {
        state[key] = value
      }
    )
    .addCase(actions.managementSettingDepartmentResetData, state => {
      ;(<EntriesObject>Object)
        .entries(initialState)
        .forEach(
          <T extends keyof ManagementSettingDepartmentState>([key, value]: [
            T,
            ManagementSettingDepartmentState[T]
          ]) => {
            state[key] = value
          }
        )
    })
})

export default reducer
