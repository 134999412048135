import { createAction } from '@reduxjs/toolkit'

import { AuthInfo, LoginForm , UserProfile} from 'src/types/common'

export const managementLogin = createAction<LoginForm>('MANAGEMENT_AUTH_LOGIN')
export const managementLoginSuccess = createAction<AuthInfo>('MANAGEMENT_AUTH_LOGIN_SUCCESS')
export const managementLoginFailure = createAction<void>('MANAGEMENT_AUTH_LOGIN_FAILURE')

export const managementLogout = createAction('MANAGEMENT_AUTH_LOGOUT')

export const managementUserGetProfile = createAction('MANAGEMENT_AUTH_GET_PROFILE')
export const managementUserGetProfileSuccess = createAction<UserProfile>('MANAGEMENT_AUTH_GET_PROFILE_SUCCESS')

