import { PayloadAction, createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import { Lecture, Question, EntriesObject } from 'src/types/common'

export type AdminLectureState = Omit<Lecture, 'trainingStep' | 'trainingType'>

export type QuestionPatch<Key extends keyof Question> = {
  key: Key
  value: Question[Key]
  questionIndex: number
}

export const initialState: AdminLectureState = {
  id: null,
  name: '',
  caution: '',
  description: '',
  questions: [],
  lectureMaterials: [],
  lectureVideos: [],
  progress: 0,
  lecturePassed: false,
  lecturePassedAt: null,
  passedByAssessment: false
}

const adminLectureReducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.adminLectureLoadData, (state, { payload: { lecture } }) => {
      state.id = lecture.id
      state.name = lecture.name
      state.caution = lecture.caution
      state.description = lecture.description
      state.lectureMaterials = lecture.lectureMaterials
      state.questions = lecture.questions
    })
    .addCase(actions.adminLectureChange, (state, { payload: { key, value } }) => {
      state[key] = value
    })
    .addCase(actions.adminLectureAddMaterial, (state, { payload: { fileSignedId, fileName, fileExtname } }) => {
      state.lectureMaterials.push({
        name: '',
        fileSignedId,
        fileUrl: '',
        fileName,
        fileExtname
      })
    })
    .addCase(actions.adminLectureMaterialChange, (state, { payload: { key, value, lectureMaterialId } }) => {
      state.lectureMaterials[lectureMaterialId][key] = value
    })
    .addCase(actions.adminLectureAddQuestion, state => {
      state.questions.push({
        questionTypeId: 1, // 仮説対応
        name: '',
        problemText: '',
        commentaryText: '',
        questionOptions: [],
        priority: state.questions.length + 1,
      })
    })
    .addCase(
      actions.adminLectureQuestionChange,
      <T extends keyof Question>(
        state: AdminLectureState,
        { payload: { key, value, questionIndex } }: PayloadAction<QuestionPatch<T>>
      ) => {
        state.questions[questionIndex][key] = value
      }
    )
    .addCase(actions.adminLectureResetData, state => {
      (<EntriesObject>Object).entries(initialState).forEach(
        <T extends keyof AdminLectureState>([key, value]: [T, AdminLectureState[T]]) => {
          state[key] = value
        }
      )
    })
})

export default adminLectureReducer
