import { createAction } from '@reduxjs/toolkit'
import { AdminCompanyState, CompanyChangeType } from 'src/store/admin/company/reducer'
import { Company } from 'src/types/admin'

export const adminCompanyLoadData = createAction<{
  company: Company
}>('ADMIN_COMPANY_LOAD_DATA')

export const adminCompanyChange = createAction<CompanyChangeType<keyof AdminCompanyState>>('ADMIN_COMPANY_CHANGE')

export const adminCompanyResetData = createAction('ADMIN_COMPANY_RESET_DATA')
