import { useGlobalModalContext } from "./GlobalModal";
import * as React from 'react';
import { forwardRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='down' ref={ref} {...props} />
})

export const DeleteModal = () => {
  const { store, hideDeleteModal } = useGlobalModalContext();

  const handleModalToggle = (isConfirm: boolean) => {
    hideDeleteModal();
    if (isConfirm) {
      store.modalProps.confirmFunc && store.modalProps.confirmFunc();
    } else {
      store.modalProps.cancelFunc && store.modalProps.cancelFunc();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={hideDeleteModal}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {store.modalProps.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {store.modalProps.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          onClick={() => handleModalToggle(false)}
          variant='contained'
          color='inherit'
        >
          キャンセル
        </Button>
        <Button variant='contained' color='error' onClick={() => handleModalToggle(true)}>
          <Typography variant='body1'>
            { store.modalProps.button ? store.modalProps.button : '削除' }
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
