import axios from 'src/utils/axios'

import { PaginationMeta } from 'src/types/common'
import { TmsUser, TmsUserDetail, TmsUserLecture, TmsDepartmentUserDetail } from 'src/types/management'

export type ManagementTmsUserSearch = {
  page?: number
  per?: number
  departmentId?: null | number
  departmentGroupId?: null | number
}

export type ManagementTmsUserLecturesSearch = {
  page?: number
  per?: number
}

export type ManagementTmsUsersResponseType = {
  users: TmsUser[]
  meta: PaginationMeta
}

export const index = async ({
  params
}: {
  params: ManagementTmsUserSearch
}): Promise<ManagementTmsUsersResponseType> => {
  const { data } = await axios.get(`/management/tms/users`, { params })

  return data
}

export const show = async (userId: number | string): Promise<TmsUserDetail> => {
  const { data } = await axios.get(`/management/tms/users/${userId}`)

  return data.user
}

export const getDepartmentUser = async (departmentId: number, userId: number): Promise<TmsDepartmentUserDetail> => {
  const { data } = await axios.get(`/management/tms/departments/${departmentId}/users/${userId}`)

  return data.user
}

export const getLectures = async (
  userId: number | string,
  params?: ManagementTmsUserLecturesSearch
): Promise<{
  userLectures: TmsUserLecture[]
  meta: PaginationMeta
}> => {
  const { data } = await axios.get(`/management/tms/users/${userId}/user_lectures`, { params })

  return data
}
