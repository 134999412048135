import { createAction } from '@reduxjs/toolkit'

export const userExamQuestionsSetExamNum = createAction<{
  examNum: number
}>('USER_EXAM_QUESTIONS_SET_EXAM_NUM')

export const userExamQuestionsSetCurrentSeq = createAction<{
  currentSeq: number
}>('USER_EXAM_QUESTIONS_SET_CURRENT_SEQ')

export const userExamQuestionsResetData = createAction('USER_EXAM_QUESTIONS_RESET_DATA')
