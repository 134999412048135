import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import * as actions from './actions'
import { FormUpdatePasswordType } from 'src/apis/user/user'
import { EntriesObject } from 'src/types/common'

export type UserUpdatePasswordState = FormUpdatePasswordType & {
  isSuccess: boolean | null
}

export const initialState: UserUpdatePasswordState = {
  password: '',
  passwordConfirmation: '',
  isSuccess: null
}

export type UserUpdatePasswordChangeType<T extends keyof UserUpdatePasswordState> = {
  key: T
  value: UserUpdatePasswordState[T]
}

const userTrainingContentReducer = createReducer(initialState, builder => {
  builder
    .addCase(
      actions.userUpdatePasswordChange,
      <T extends keyof UserUpdatePasswordState>(
        state: UserUpdatePasswordState,
        { payload: { key, value } }: PayloadAction<UserUpdatePasswordChangeType<T>>
      ) => {
        state[key] = value
      }
    )
    .addCase(actions.userUpdatePasswordResetData, state => {
      ;(<EntriesObject>Object)
        .entries(initialState)
        .forEach(<T extends keyof UserUpdatePasswordState>([key, value]: [T, UserUpdatePasswordState[T]]) => {
          state[key] = value
        })
    })
    .addCase(actions.userUpdatePasswordSuccess, state => {
      state.isSuccess = true
    })
})

export default userTrainingContentReducer
