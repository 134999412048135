import { createContext, useState, ReactNode } from 'react'

import { Role } from 'src/types/common'

export type RoleContextValue = {
  role: Role | null
  saveRole: (role: Role) => void
}

export const RoleContext = createContext<RoleContextValue>({
  role: null,
  saveRole: () => null,
})

export const RoleProvider = ({ children }: { children: ReactNode }) => {
  const [role, setRole] = useState<Role | null>(null)

  const saveRole = (value: Role) => {
    setRole(value)
  }

  return <RoleContext.Provider value={{ role, saveRole }}>{children}</RoleContext.Provider>
}

