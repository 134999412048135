import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'
import initialState from 'src/store/state'
import { UserProfile } from 'src/types/common'

export type ManagementLoginState = {
  client: string
  'access-token': string
  uid: string
  profile?: UserProfile
}

const managementLoginReducer = createReducer(initialState.userLogin, builder => {
  builder.addCase(actions.managementLoginSuccess, (state, action) => {
    state.client = action.payload.client
    state['access-token'] = action.payload['access-token']
    state.uid = action.payload.uid
  }),
  builder.addCase(actions.managementUserGetProfileSuccess, (state, action) => {
    state.profile = action.payload
  })
})

export default managementLoginReducer
