import { useState, SyntheticEvent, Fragment } from 'react'

import { useRouter } from 'next/router'

import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'

import { logout } from 'src/apis/admin/auth'
import handleError from 'src/utils/handleError'
import { AdminAuthKey } from 'src/pages/admin/login/saga'

const UserDropdown = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const router = useRouter()

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const onLogout = async () => {
    try {
      await logout()
      localStorage.removeItem(AdminAuthKey)
      window.location.href = '/admin/login'
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <Fragment>
      <Avatar
        onClick={handleDropdownOpen}
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
        src='/images/avatars/1.png'
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ py: 2 }} onClick={onLogout}>
          <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
          ログアウト
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default UserDropdown
