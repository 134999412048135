import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    text: {
      primary: '#000000', 
    },
    primary: {
      main: '#FFEE58',
      dark: '#FCDE01',
      light: '#FFF176',
    },
    secondary: {
      main: '#000000',
      dark: '#000000',
      light: '#000000',
    },
    info: {
      main: '#00BCD4',
      dark: '#0288D1',
      light: '#E3F2FD',
    },
    success: {
      main: '#00BCD4',
      dark: '#00ACC1',
      light: '#26C6DA',
    },
    warning: {
      main: '#00BCD4',
      dark: '#00ACC1',
      light: '#FDEDED',

    },
    error: {
      main: '#D32F2F',
      dark: '#C62828',
      light: '#EF5350',
    },
  },
  typography: {
    allVariants: {
      whiteSpace: 'pre-line',
    },
  }
})

export default theme;
