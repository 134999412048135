import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export type UserExamsState = {
  searchConditions: {
    page: number
  }
  totalCount: number
}

export const initialState: UserExamsState = {
  searchConditions: {
    page: 1
  },
  totalCount: 0
}

const userExamsReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userExamsUpdateSearchCondition, (state, action) => {
    state.searchConditions.page = action.payload.page
  })
  builder.addCase(actions.userExamsFetchDataSuccess, (state, action) => {
    state.totalCount = action.payload.totalCount
  })
})

export default userExamsReducer
