import { createAction } from '@reduxjs/toolkit'

import { AdminTrainingContentState, AdminTrainingContentChangeKey, AdminTrainingContentChange } from 'src/store/admin/training_content/reducer'

export const adminTrainingContentChange = createAction<AdminTrainingContentChange<keyof AdminTrainingContentChangeKey>>('ADMIN_TRAINING_CONTENT_CHANGE')

export const adminTrainingContentAddTrainingStep = createAction<number>('ADMIN_TRAINING_CONTENT_ADD_TRAINING_STEP')
export const adminTrainingContentDeleteTrainingStep = createAction<{
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
}>('ADMIN_TRAINING_CONTENT_DELETE_TRAINING_STEP')


export const adminTrainingContentAddTrainingType = createAction<{ name: string }>(
  'ADMIN_TRAINING_CONTENT_ADD_TRAINING_TYPE'
)

export const adminTrainingContentDeleteTrainingType = createAction<{
  trainingContentId: number
  trainingTypeId: number
}>(
  'ADMIN_TRAINING_CONTENT_DELETE_TRAINING_TYPE'
)

export const adminTrainingContentAddLecture = createAction<{
  trainingTypeIndex: number
  trainingStepIndex: number
}>('ADMIN_TRAINING_CONTENT_ADD_LECTURE')

export const adminTrainingContentDeleteLecture = createAction<{
  trainingContentId: number
  trainingTypeId: number
  trainingStepId: number
  lectureId: number
}>('ADMIN_TRAINING_CONTENT_DELETE_LECTURE')

export const adminTrainingContentTrainingStepChange = createAction<{
  trainingTypeIndex: number
  trainingStepIndex: number
  name: string
}>('ADMIN_TRAINING_CONTENT_TRAINING_STEP_CHANGE')

export const adminTrainingContentLectureChange = createAction<{
  trainingTypeIndex: number
  trainingStepIndex: number
  lectureIndex: number
  name: string
}>('ADMIN_TRAINING_CONTENT_LECTURE_CHANGE')

export const adminTrainingContentTrainingTypeChange = createAction<{
  trainingTypeIndex: number
  name: string
}>('ADMIN_TRAINING_CONTENT_TRAINING_TYPE_CHANGE')

export const adminTrainingContentFetchData = createAction<{
  trainingContentId: number
}>('ADMIN_TRAINING_CONTENT_FETCH_DATA')

export const adminTrainingContentLoadData = createAction<{
  trainingContent: AdminTrainingContentState
}>('ADMIN_TRAINING_CONTENT_LOAD_DATA')

export const adminTrainingContentResetData = createAction('ADMIN_TRAINING_CONTENT_RESET_DATA')
