import { createReducer } from '@reduxjs/toolkit'
import * as actions from './actions'

export type SkillResultType = {
  skillName: string;
  passed: boolean;
}

export type DxSkillSubcategoryType = {
  dxSkillSubcategoryName: string;
  skills: SkillResultType[];
}

export type LiteracyResultType = {
  literacyAssesmentScore: number;
  dx: boolean;
  literacyResults: {
      dxSkillCategoryName: string;
      dxSkillSubcategories: DxSkillSubcategoryType[];
  }[];
};


export type UserAssessmentLiteracyState = {
  result: LiteracyResultType | null
}

export const initialState: UserAssessmentLiteracyState = {
  result: null
}

const userExamsReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userAssessmentLiteracySetResult, (state, action) => {
    state.result = action.payload;
  })
})

export default userExamsReducer
