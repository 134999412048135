import { all, takeLatest, put, call } from 'redux-saga/effects'
import { userTrainingContentCategoriesFetch, userTrainingContentCategoriesFetchSuccess } from './actions'
import * as UserTrainingContentCategoryApi from 'src/apis/user/training_content_category'
import { TrainingContentCategorySearch } from 'src/apis/user/training_content_category'
import { IndexResponseType } from 'src/apis/user/training_content_category'

import handleError from 'src/utils/handleError'

export const UserAuthKey = 'UserAuthKey'

type LoginAction = {
  type: string
  payload: TrainingContentCategorySearch
}

function* userTrainingContentCategoriesFetchSaga(action: LoginAction) {
  try {
    const trainingContents: IndexResponseType = yield call(UserTrainingContentCategoryApi.index, action.payload)
    yield put(
      userTrainingContentCategoriesFetchSuccess({
        trainingContentCategories: trainingContents.trainingContentCategories,
        totalCount: trainingContents.totalCount || 0
      })
    )
  } catch (error) {
    handleError(error)
  }
}

function* userTrainingContentsSaga() {
  yield all([takeLatest(userTrainingContentCategoriesFetch.type, userTrainingContentCategoriesFetchSaga)])
}

export default userTrainingContentsSaga
