import { Box, Button, ButtonProps, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography, TypographyProps, styled } from "@mui/material"
import { useState } from "react"
import * as UserLectureApi from 'src/apis/user/lecture'
import { ProblemReport, RouteType } from "src/apis/user/lecture"
import handleError from "src/utils/handleError"
import toast from 'src/views/toast'

type Props = {
  problemModalOpen: boolean
  closeReportModal: () => void
  questionId: number
  routeType: RouteType
}

export const DX_ASSESSMENT = "dx_assessment"
export const LITERACY_ASSESSMENT = "literacy_assessment"
export const NORMAL_LECTURE = "normal_lecture"
export const LAST_EXAM = "last_exam"
export const OTHERS = "others"

export type ReportType =
  | "正解の選択肢がおかしい"
  | "問題文に誤りがある"
  | "解説文に誤りがある"
  | "その他"
  | "画像が登録されていない"
  ;

const UseProblemReportTypography = styled(Typography)<TypographyProps>(() => ({
  fontSize:'16px', fontWeight:'500', lineHeight:'20px', letterSpacing:'0.46px'
}))

const CancelButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: 'rgba(236, 239, 241, 0.60)',
}))

const PROBLEM_REPORTS = [
  {value: '正解の選択肢がおかしい'},
  {value: '問題文に誤りがある'},
  {value: '解説文に誤りがある'},
  {value: '画像が登録されていない'},
  {value: 'その他'},
]

const ProblemReportModal = ({ problemModalOpen, closeReportModal, questionId, routeType }: Props) => {
  const [reportType, setReportType] = useState<ReportType | ''>('');
  const [description, setDescription] = useState<string>('');

  const handleSelectedReport = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportType(event.target.value as ReportType);
  };
  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const onClickReport = () => {
    if (!reportType || !description) return
    const reportData: ProblemReport = {
      reportType: reportType,
      description: description,
      questionId: questionId,
      routeType: routeType
    }
    sendProblemReportData(reportData)
    closeProblemReportModal()
  }
  const onClickCancel = () => {
    closeProblemReportModal()
  }
  const sendProblemReportData = async (reportData: ProblemReport) => {
    try {
      await UserLectureApi.sendProblemReport(reportData)
      toast({ type: 'success', message: '報告を送信しました' })
    } catch (error) {
      handleError(error)
    }
  }
  const closeProblemReportModal = () => {
    closeReportModal()
    setReportType('')
    setDescription('')
  }

  if (!problemModalOpen) return <></>

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        minHeight:'540px'
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Stack alignItems="flex-start" width='100%' sx={{ padding: '20px' }}>
          <UseProblemReportTypography>
            報告内容
          </UseProblemReportTypography>
        </Stack>
        <FormControl >
          <RadioGroup value={reportType} onChange={handleSelectedReport}>
            {PROBLEM_REPORTS.map((problemReport) => (
              <FormControlLabel
                key={problemReport.value}
                value={problemReport.value}
                control={<Radio />}
                label={<Typography fontSize='16px' fontWeight='400' lineHeight='24px' letterSpacing='0.15px'>{problemReport.value}</Typography>}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Stack alignItems="flex-start" width='100%' sx={{ padding: '20px' }}>
          <UseProblemReportTypography>
            自由入力欄
          </UseProblemReportTypography>
        </Stack>
        <TextField
          label="問題の詳細"
          multiline
          rows={5}
          value={description}
          onChange={handleDescription}
          sx={{ width: '80%', marginBottom: '32px'}}
        />
        <Stack direction='row' justifyContent="space-between" alignItems="center" width='80%' marginBottom='32px'>
          <Button
            onClick={onClickReport}
            variant='contained'
            disabled={reportType==='' || description===''}
            sx={{ padding: '8px 22px'}}
          >
            送信する
          </Button>
          <CancelButton
            onClick={onClickCancel}
            variant='contained'
            sx={{ padding: '8px 22px'}}
          >
            キャンセル
          </CancelButton>
        </Stack>
      </Stack>
    </Box>
  )
}

export default ProblemReportModal
