import { createAction } from '@reduxjs/toolkit'

import { TrainingContent } from 'src/types/user'
import { TrainingContentShow  } from 'src/apis/user/training_content'
import { TrainingContentLecturesSearch, TrainingContentLecturesResponseType } from 'src/apis/user/lecture'

export const userTrainingContentFetch = createAction<TrainingContentShow>('USER_TRAINING_CONTENT_FETCH')

export const userTrainingContentFetchSuccess = createAction<{
  trainingContent: TrainingContent
}>('USER_TRAINING_CONTENT_FETCH_SUCCESS')

export const userTrainingContentLecturesFetch = createAction<{
  trainingContentId: number
  params: TrainingContentLecturesSearch
}>('USER_TRAINING_CONTENT_LECTURES_FETCH')

export const userTrainingContentLecturesFetchSuccess = createAction<TrainingContentLecturesResponseType>(
  'USER_TRAINING_CONTENT_LECTURES_SUCCESS'
)

export const userTrainingContentUpdateSearchCondition = createAction<TrainingContentLecturesSearch>(
  'USER_TRAINING_CONTENT_UPDATE_SEARCH_CONDITION'
)
