import { createAction } from '@reduxjs/toolkit'
import { SettingDepartment } from 'src/types/management'
import { ManagementSettingDepartmentState, DepartmentChangeType } from 'src/store/management/setting/department/reducer';

export const managementSettingDepartmentLoadData = createAction<{
  department: SettingDepartment
}>('MANAGEMENT_SETTING_DEPARTMENT_LOAD_DATA')


export const managementSettingDepartmentChange = createAction<DepartmentChangeType<keyof ManagementSettingDepartmentState>>('MANAGEMENT_SETTING_DEPARTMENT_CHANGE')

export const managementSettingDepartmentResetData = createAction('MANAGEMENT_SETTING_DEPARTMENT_RESET_DATA')
