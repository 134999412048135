import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import * as actions from './actions'
import { EntriesObject, TrainingContent } from 'src/types/common'
import { TrainingStep, TrainingType } from 'src/types/common'

export type TrainingStepUpdate = Pick<TrainingStep, 'name' | 'lectures' | 'fileName' | 'fileUrl'> &
  Partial<Pick<TrainingStep, 'id'>>
export type TrainingTypeUpdate = Pick<TrainingType, 'name' | 'id'> & {
  trainingSteps: TrainingStepUpdate[]
}

export type AdminTrainingContentState = Omit<TrainingContent, 'userCount' | 'lectureCount' | 'trainingTypes'> & {
  priceTypeId: number | null
  trainingContentCategoryId: number | null
  trainingTypes: TrainingTypeUpdate[]
}

export type AdminTrainingContentChangeKey = Pick<
  AdminTrainingContentState,
  | 'name'
  | 'description'
  | 'status'
  | 'trainingContentCategoryId'
  | 'expectedTrainingTime'
  | 'examMinutes'
  | 'examNum'
  | 'passingNum'
  | 'priceTypeId'
>

export type AdminTrainingContentChange<T extends keyof AdminTrainingContentChangeKey> = {
  key: T
  value: AdminTrainingContentState[T]
}

export const initialState: AdminTrainingContentState = {
  id: -1,
  status: 'draft',
  priceTypeId: -1,
  expectedTrainingTime: 60,
  examMinutes: 60,
  examNum: 0,
  passingNum: 0,
  priceType: {
    id: undefined,
    name: ''
  },
  trainingContentCategoryId: -1,
  trainingContentCategory: {
    id: undefined,
    name: ''
  },
  name: '',
  description: '',
  trainingTypes: [
    {
      name: '',
      trainingSteps: [
        {
          name: '',
          fileName: '',
          fileUrl: '',
          lectures: []
        }
      ]
    }
  ]
}

const adminTrainingContentsReducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.adminTrainingContentAddTrainingStep, (state, action) => {
      state.trainingTypes[action.payload].trainingSteps.push({
        name: '',
        fileName: '',
        fileUrl: '',
        lectures: []
      })
    })
    .addCase(actions.adminTrainingContentAddLecture, (state, { payload }) => {
      state.trainingTypes[payload.trainingTypeIndex].trainingSteps[payload.trainingStepIndex].lectures.push({
        name: '',
        questions: [],
        lectureMaterials: [],
        lectureVideos: [],
        progress: 0,
        lecturePassed: false,
        lecturePassedAt: null,
        passedByAssessment: false
      })
    })
    .addCase(actions.adminTrainingContentAddTrainingType, (state, { payload }) => {
      state.trainingTypes.push({
        name: payload.name,
        trainingSteps: [
          {
            name: '',
            fileName: '',
            fileUrl: '',
            lectures: []
          }
        ]
      })
    })
    .addCase(actions.adminTrainingContentTrainingStepChange, (state, { payload }) => {
      state.trainingTypes[payload.trainingTypeIndex].trainingSteps[payload.trainingStepIndex].name = payload.name
    })
    .addCase(actions.adminTrainingContentTrainingTypeChange, (state, { payload }) => {
      state.trainingTypes[payload.trainingTypeIndex].name = payload.name
    })
    .addCase(actions.adminTrainingContentLectureChange, (state, { payload }) => {
      state.trainingTypes[payload.trainingTypeIndex].trainingSteps[payload.trainingStepIndex].lectures[
        payload.lectureIndex
      ].name = payload.name
    })
    .addCase(
      actions.adminTrainingContentChange,
      <T extends keyof AdminTrainingContentChangeKey>(
        state: AdminTrainingContentState,
        { payload }: PayloadAction<AdminTrainingContentChange<T>>
      ) => {
        state[payload.key] = payload.value
      }
    )
    .addCase(actions.adminTrainingContentLoadData, (state, { payload: { trainingContent } }) => {
      state.id = trainingContent.id
      state.name = trainingContent.name
      state.description = trainingContent.description
      state.status = trainingContent.status
      state.trainingContentCategoryId = trainingContent.trainingContentCategoryId
      state.trainingContentCategory = trainingContent.trainingContentCategory
      state.expectedTrainingTime = trainingContent.expectedTrainingTime
      state.examMinutes = trainingContent.examMinutes
      state.examNum = trainingContent.examNum
      state.passingNum = trainingContent.passingNum
      state.priceTypeId = trainingContent.priceTypeId
      state.priceType = trainingContent.priceType
      state.trainingTypes = trainingContent.trainingTypes
    })
    .addCase(actions.adminTrainingContentResetData, state => {
      ;(<EntriesObject>Object)
        .entries(initialState)
        .forEach(<T extends keyof AdminTrainingContentState>([key, value]: [T, AdminTrainingContentState[T]]) => {
          state[key] = value
        })
    })
})

export default adminTrainingContentsReducer
