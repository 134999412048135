import { all, takeLatest, put, call } from 'redux-saga/effects'
import { adminLogin, adminLoginSuccess } from './actions'
import { login } from 'src/apis/admin/auth'

import handleError from 'src/utils/handleError'

import { LoginForm } from 'src/types/common'

export const AdminAuthKey = 'AdminAuthKey'

type LoginAction = {
  type: string
  payload: LoginForm
}

function* adminLoginSaga(action: LoginAction) {
  try {
    const { headers } = yield call(login, action.payload)
    yield put(adminLoginSuccess(headers))

    // save into local storage
    localStorage.setItem(
      AdminAuthKey,
      JSON.stringify({
        client: headers.client,
        'access-token': headers['access-token'],
        uid: headers.uid
      })
    )
    location.href = '/admin/training_contents'
  } catch (error) {
    handleError(error)
  }
}

function* loginSaga() {
  yield all([takeLatest(adminLogin.type, adminLoginSaga)])
}

export default loginSaga
