import { createAction } from '@reduxjs/toolkit'

import { TrainingContentCategory } from 'src/types/user'
import { TrainingContentCategorySearch  } from 'src/apis/user/training_content_category'

export const userTrainingContentCategoriesFetch = createAction<TrainingContentCategorySearch>('USER_TRAINING_CONTENT_CATEGORIES_FETCH')
export const userTrainingContentCategoriesUpdateSearchCondition = createAction<TrainingContentCategorySearch>(
  'USER_TRAINING_CONTENT_CATEGORIES_UPDATE_SEARCH_CONDITION'
)

export const userTrainingContentCategoriesFetchSuccess = createAction<{
  trainingContentCategories: TrainingContentCategory[]
  totalCount: number
}>('USER_TRAINING_CONTENT_CATEGORIES_FETCH_SUCCESS')
