import { createAction } from '@reduxjs/toolkit'
import { SettingDepartmentGroup } from 'src/types/management'
import { ManagementSettingDepartmentGroupState, DepartmentGroupChangeType } from 'src/store/management/setting/department_group/reducer';

export const managementSettingDepartmentGroupLoadData = createAction<{
  departmentGroup: SettingDepartmentGroup
}>('MANAGEMENT_SETTING_DEPARTMENT_GROUP_LOAD_DATA')


export const managementSettingDepartmentGroupChange = createAction<DepartmentGroupChangeType<keyof ManagementSettingDepartmentGroupState>>('MANAGEMENT_SETTING_DEPARTMENT_GROUP_CHANGE')

export const managementSettingDepartmentGroupResetData = createAction('MANAGEMENT_SETTING_DEPARTMENT_GROUP_RESET_DATA')
