import { createReducer } from '@reduxjs/toolkit'

import * as actions from './actions'

export type UserExamQuestionsState = {
  currentSeq: number
  examNum: number
}

export const initialState: UserExamQuestionsState = {
  currentSeq: 1,
  examNum: 0
}

const userExamQuestionsReducer = createReducer(initialState, builder => {
  builder.addCase(actions.userExamQuestionsSetExamNum, (state, action) => {
    state.examNum = action.payload.examNum
  })
  builder.addCase(actions.userExamQuestionsSetCurrentSeq, (state, action) => {
    state.currentSeq = action.payload.currentSeq
  })
  builder.addCase(actions.userExamQuestionsResetData, state => {
    state.currentSeq = 1
    state.examNum = 0
  })
})

export default userExamQuestionsReducer
