import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { AdminAuthKey } from 'src/pages/admin/login/saga'
import { UserAuthKey } from 'src/store/user/auth/saga'
import { ManagementAuthKey } from 'src/store/management/auth/saga'
import { AuthInfo } from 'src/types/common'

const axiosApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL
})

function isAdminRoute() {
  return location.pathname.startsWith(`/admin`)
}

function isUserRoute() {
  return location.pathname.startsWith(`/user`)
}

function isManagementRoute() {
  return location.pathname.startsWith(`/management`)
}

const handleUpdateAccessTokenIfNeed = (response: AxiosResponse<any>) => {
  const { headers } = response
  const { uid, client, 'access-token': accessToken } = headers
  if (!accessToken || !uid || !client) return
  const auth: AuthInfo = { uid, client, 'access-token': accessToken }
  const configs = [
    {
      key: AdminAuthKey,
      isRouteFn: isAdminRoute
    },
    {
      key: UserAuthKey,
      isRouteFn: isUserRoute
    },
    {
      key: ManagementAuthKey,
      isRouteFn: isManagementRoute
    },
  ]

  const selectedConfig = configs.find(config => config.isRouteFn());
  if (!selectedConfig) return;
  localStorage.setItem(selectedConfig.key, JSON.stringify(auth))
}

function setDefaultProperties(axiosInstace: AxiosInstance) {
  axiosInstace.defaults.timeout = 2 * 60 * 60 * 1000

  axiosInstace.interceptors.request.use(
    config => {
      let auth: null | AuthInfo = null
      try {
        if (
          location.pathname.startsWith(`/admin`) &&
          ['/admin/password/edit', '/admin/login', '/admin/password/forgot'].every(
            path => !location.pathname.includes(path)
          )
        ) {
          auth = JSON.parse(localStorage.getItem(AdminAuthKey) || '{}')
        } else if (
          location.pathname.startsWith(`/user`) &&
          ['/user/password/edit', '/user/login', '/user/password/forgot'].every(
            path => !location.pathname.includes(path)
          )
        ) {
          auth = JSON.parse(localStorage.getItem(UserAuthKey) || '{}')
        } else if (
          location.pathname.startsWith(`/management`) &&
          ['/management/password/edit', '/management/login', '/management/password/forgot'].every(
            path => !location.pathname.includes(path)
          )
        ) {
          auth = JSON.parse(localStorage.getItem(ManagementAuthKey) || '{}')
        }

        if (auth && config.headers) {
          config.headers['uid'] = auth.uid
          config.headers['client'] = auth.client
          config.headers['access-token'] = auth['access-token']
        }
      } catch (error) {}

      return config
    },
    undefined,
    { synchronous: true }
  )

  axiosInstace.interceptors.response.use(
    (response: any) => {
      handleUpdateAccessTokenIfNeed(response);

      return response
    },
    (error: AxiosError) => {
      console.log('error.response', error)

      const status = (error.response && error.response!.status) || 0
      if (status === 401) {
        if (location.pathname.startsWith('/admin') && !location.pathname.startsWith('/admin/login')) {
          location.href = '/admin/login'
        } else if (location.pathname.startsWith('/user') && !location.pathname.startsWith('/user/login')) {
          location.href = '/user/login'
        } else if (location.pathname.startsWith('/management') && !location.pathname.startsWith('/management/login')) {
          location.href = '/management/login'
        }
      } else if (status >= 500) {
        console.log('API error with status', status)
      }

      return Promise.reject(error)
    }
  )
}

setDefaultProperties(axiosApi)
setDefaultProperties(axiosApi)

export { axiosApi }
export default axiosApi
