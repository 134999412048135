import { createAction } from '@reduxjs/toolkit'

import { TrainingContent } from 'src/types/user'
import { TrainingContentsSearch, TrainingContentsSearchParam } from 'src/apis/user/training_content'

export const userTrainingContentsFetch = createAction<TrainingContentsSearch>('USER_TRAINING_CONTENTS_FETCH')
export const userTrainingContentsUpdateSearchCondition = createAction<TrainingContentsSearchParam>(
  'USER_TRAINING_CONTENTS_UPDATE_SEARCH_CONDITION'
)

export const userTrainingContentsFetchSuccess = createAction<{
  trainingContents: TrainingContent[]
  totalCount: number
}>('USER_TRAINING_CONTENTS_FETCH_SUCCESS')
