import { all, takeLatest, put, call } from 'redux-saga/effects'
import handleError from 'src/utils/handleError'

import { managementTmsUsersFetch, managementTmsUsersFetchSuccess } from './actions'

import * as ManagementTmsUserApi from 'src/apis/management/tms/user'
import { ManagementTmsUserSearch, ManagementTmsUsersResponseType } from 'src/apis/management/tms/user'

type TmsUsersFetchAction = {
  type: string
  payload: ManagementTmsUserSearch
}

function* managementTmsUsersFetchSage(action: TmsUsersFetchAction) {
  try {
    const data: ManagementTmsUsersResponseType = yield call(ManagementTmsUserApi.index, { params: action.payload })
    yield put(managementTmsUsersFetchSuccess(data))
  } catch (error) {
    handleError(error)
  }
}

function* managementTmsUsersSaga() {
  yield all([
    takeLatest(managementTmsUsersFetch.type, managementTmsUsersFetchSage),
  ])
}

export default managementTmsUsersSaga
