import { createReducer } from '@reduxjs/toolkit'

// import {AppState} from './types'
// import initialState from './state'
import * as actions from './actions'
import initialState from 'src/store/state'
import { UserProfile } from 'src/types/common'

export type UserLoginState = {
  client: string
  'access-token': string
  uid: string
  profile?: UserProfile
}

const userLoginReducer = createReducer(initialState.userLogin, builder => {
  builder.addCase(actions.userLoginSuccess, (state, action) => {
    state.client = action.payload.client
    state['access-token'] = action.payload['access-token']
    state.uid = action.payload.uid
  })
  builder.addCase(actions.userGetProfileSuccess, (state, action) => {
    state.profile = action.payload
  })
})

export default userLoginReducer
