import { createReducer, PayloadAction } from '@reduxjs/toolkit'
import * as actions from './actions'

import { EntriesObject } from 'src/types/common'

export type AdminCompanyState = {
  id: number
  name: string
  trialFinishedOn: string | null
  billingPlanId: number | null
  applicantAttributes: {
    name: string
    email: string
    password: string
  }
}

export type CompanyChangeType<T extends keyof AdminCompanyState> = {
  key: T
  value: AdminCompanyState[T]
}

export const initialState: AdminCompanyState = {
  id: 0,
  name: '',
  trialFinishedOn: null,
  billingPlanId: null,
  applicantAttributes: {
    name: '',
    email: '',
    password: ''
  }
}

const managementUserReducer = createReducer(initialState, builder => {
  builder
    .addCase(actions.adminCompanyLoadData, (state, { payload: { company } }) => {
      // state.billingPlanId = company.billingPlan?.id || null
      state.id = company.id
      state.name = company.name
      state.trialFinishedOn = company.trialFinishedOn
      state.applicantAttributes.name = company.applicant.name
      state.applicantAttributes.email = company.applicant.email
    })
    .addCase(
      actions.adminCompanyChange,
      <T extends keyof AdminCompanyState>(
        state: AdminCompanyState,
        { payload: { key, value } }: PayloadAction<CompanyChangeType<T>>
      ) => {
        state[key] = value
      }
    )
    .addCase(actions.adminCompanyResetData, state => {
      ; (<EntriesObject>Object)
        .entries(initialState)
        .forEach(<T extends keyof AdminCompanyState>([key, value]: [T, AdminCompanyState[T]]) => {
          state[key] = value
        })
    })
})

export default managementUserReducer
