import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'

import { AppState } from './types'
import initialState from './state'

import adminLoginReducer from 'src/pages/admin/login/reducer'
import adminTrainingContent from 'src/store/admin/training_content/reducer'
import adminLectureReducer from 'src/store/admin/lecture/reducer'
import adminQuestionReducer from 'src/store/admin/question/reducer'
import adminCompanyReducer from 'src/store/admin/company/reducer'

import userLoginReducer from 'src/store/user/auth/reducer'
import userTrainingContentsReducer from 'src/store/user/training_contents/reducer'
import userTrainingContentReducer from 'src/store/user/training_content/reducer'
import userTrainingContentCategoriesReducer from 'src/store/user/training_content_categories/reducer'
import userExamsReducer from 'src/store/user/user_exams/reducer'
import userExamQuestionsReducer from 'src/store/user/user_exam_questions/reducer'
import userAssessmentLiteracyReducer from 'src/store/user/assessment_literacy/reducer'
import userAssessmentDxReducer from 'src/store/user/assessment_dx/reducer'
import userUpdatePasswordReducer from 'src/store/user/update_password/reducer'

import managementLoginReducer from 'src/store/management/auth/reducer'
import managementSettingDepartmentGroupReducer from 'src/store/management/setting/department_group/reducer'
import managementSettingDepartmentReducer from 'src/store/management/setting/department/reducer'
import managementTmsUsersReducer from 'src/store/management/tms/users/reducer'

const combinedReducer = combineReducers({
  adminLogin: adminLoginReducer,
  adminTrainingContent: adminTrainingContent,
  adminLecture: adminLectureReducer,
  adminQuestion: adminQuestionReducer,
  adminCompany: adminCompanyReducer,

  userLogin: userLoginReducer,
  userTrainingContents: userTrainingContentsReducer,
  userTrainingContent: userTrainingContentReducer,
  userTrainingContentCategories: userTrainingContentCategoriesReducer,
  userExams: userExamsReducer,
  userExamQuestions: userExamQuestionsReducer,
  userAssessmentLiteracy: userAssessmentLiteracyReducer,
  userAssessmentDx: userAssessmentDxReducer,
  userPassword: userUpdatePasswordReducer,

  managementLogin: managementLoginReducer,
  managementSettingDepartmentGroup: managementSettingDepartmentGroupReducer,
  managementSettingDepartment: managementSettingDepartmentReducer,
  managementTmsUsers: managementTmsUsersReducer,
})

function rootReducer(state: AppState = initialState, action: any) {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload }
    default:
      return combinedReducer(state, action)
  }
}

export default rootReducer
