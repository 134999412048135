import axios from 'axios'

import toast from 'src/views/toast'
import { captureException } from '@sentry/nextjs'

const handleError = (error: any, { showErrorWithToast = true } = {}) => {
  console.log('error', error?.response)
  captureException(error)
  if (showErrorWithToast) {
    if (axios.isAxiosError(error)) {
      const message = error.response?.data.message || error.message
      const detail = error.response?.data.detail

      if (detail && Object.keys(detail).length > 0) {
        Object.values(detail).forEach(errorArray => {
          toast({
            type: 'error',
            message: (errorArray as string[])[0]
          })
        })
      } else if (error.response?.data.errors && error.response?.data.errors.length > 0) {
        toast({
          type: 'error',
          message: error.response?.data.errors[0]
        })
      } else if (message) {
        toast({
          type: 'error',
          message
        })
      }
    }
  }
}

export default handleError
